import React from 'react';
import ScrollToTop from 'shared/components/behaviors/scrollToTop';
import SVGSprite from 'shared/ui/atoms/icon/SVGLoaders/sprite';
import i18n from 'candidate/i18n';
import styles from './styles.scss';
import ThanksSvg from './thankyou.svg?sprite';

const ThankYou = ({'data-ui': dataUi, footer = '', message = '', svg = ThanksSvg, title = ''}) => (
  <>
    <ScrollToTop />
    <div className={styles.content} data-ui={dataUi || 'successful-submit'}>
      <h3>{title || i18n.t('form.success.title')}</h3>
      <div className={styles.icon}>
        <SVGSprite fill svg={svg} transparent />
      </div>
      <div className={styles.status} role="status">
        <h3>{message}</h3>
        {footer && <p>{footer}</p>}
      </div>
    </div>
  </>
);

export default ThankYou;
