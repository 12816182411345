import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

/**
 * A component that scrolls the viewport back to top, when the route changes.
 *
 * Browsers are starting to handle scroll restoration with history.pushState on their own in
 * the same manner they handle it with normal browser navigation, but until then you probably
 * need this.
 *
 * Example usage:
 * @example
 * import ScrollToTop from 'components/behaviors/scrollToTop';
 *
 * <ScrollToTop />
 */
export class ScrollToTop extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

ScrollToTop.propTypes = {
  /* The browser's location. You shouldn't use this property directly,react-router will set it up. */
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default withRouter(ScrollToTop);
