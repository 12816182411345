import i18n from 'candidate/i18n';

// TODO (miltos) change all values when backend is ready

export const raceOptions = [
  {
    get explanation() {
      return i18n.t('eeoc.race.options.hispanicLatino.explanation');
    },
    get label() {
      return i18n.t('eeoc.race.options.hispanicLatino.label');
    },
    value: 'hispanic_or_latino'
  },
  {
    get explanation() {
      return i18n.t('eeoc.race.options.white.explanation');
    },
    get label() {
      return i18n.t('eeoc.race.options.white.label');
    },
    value: 'white'
  },
  {
    get explanation() {
      return i18n.t('eeoc.race.options.black.explanation');
    },
    get label() {
      return i18n.t('eeoc.race.options.black.label');
    },
    value: 'black'
  },
  {
    get explanation() {
      return i18n.t('eeoc.race.options.nativeHawaiian.explanation');
    },
    get label() {
      return i18n.t('eeoc.race.options.nativeHawaiian.label');
    },
    value: 'native_hawaiian'
  },
  {
    get explanation() {
      return i18n.t('eeoc.race.options.asian.explanation');
    },
    get label() {
      return i18n.t('eeoc.race.options.asian.label');
    },
    value: 'asian'
  },
  {
    get explanation() {
      return i18n.t('eeoc.race.options.americanIndian.explanation');
    },
    get label() {
      return i18n.t('eeoc.race.options.americanIndian.label');
    },
    value: 'american_indian'
  },
  {
    get explanation() {
      return i18n.t('eeoc.race.options.multiracial.explanation');
    },
    get label() {
      return i18n.t('eeoc.race.options.multiracial.label');
    },
    value: 'multiracial'
  },
  {
    get label() {
      return i18n.t('eeoc.race.options.undisclosed.label');
    },
    value: 'undisclosed'
  }
];

export const genderOptions = [
  {
    get label() {
      return i18n.t('eeoc.gender.options.male');
    },
    value: 'male'
  },
  {
    get label() {
      return i18n.t('eeoc.gender.options.female');
    },
    value: 'female'
  },
  {
    get label() {
      return i18n.t('eeoc.race.options.undisclosed.label');
    },
    value: 'undisclosed'
  }
];

export const veteranOptions = [
  {
    get label() {
      return i18n.t('eeoc.veteran.question.answers.protected');
    },
    value: 'veteran'
  },
  {
    get label() {
      return i18n.t('eeoc.veteran.question.answers.notProtected');
    },
    value: 'not_veteran'
  },
  {
    get label() {
      return i18n.t('eeoc.veteran.question.answers.undisclosed');
    },
    value: 'undisclosed'
  }
];

export const disabilityOptions = [
  {
    get label() {
      return i18n.t('eeoc.voluntary.questions.question3.options.yes');
    },
    value: 'disabled'
  },
  {
    get label() {
      return i18n.t('eeoc.voluntary.questions.question3.options.no');
    },
    value: 'not_disabled'
  },
  {
    get label() {
      return i18n.t('eeoc.voluntary.questions.question3.options.undisclosed');
    },
    value: 'undisclosed'
  }
];
