import React, {Component} from 'react';
import styles from './styles.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Card extends Component {
  render() {
    const klass = classNames(styles.card, this.props.className, {
      [styles.isSelected]: this.props.selected,
      [styles.hoverable]: this.props.hoverable
    });
    const dataUi = this.props['data-ui'] || 'workable-card';

    return (
      <div data-ui={dataUi} onClick={this.props.onClick} className={klass}>
        {this.props.children}
      </div>
    );
  }
}

Card.propTypes = {
  hoverable: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  'data-ui': PropTypes.string
};

Card.defaultProps = {
  hoverable: true,
  'data-ui': 'workable-card'
};

Card.Content = props =>
  props.children ? <div className={classNames(styles.content, props.className)}>{props.children}</div> : null;
Card.Annotations = props =>
  props.children ? <div className={classNames(styles.annotations, props.className)}>{props.children}</div> : null;
Card.Footer = props =>
  props.children ? <div className={classNames(styles.footer, props.className)}>{props.children}</div> : null;

Card.Content.displayName = 'Card.Content';
Card.Annotations.displayName = 'Card.Annotations';
Card.Footer.displayName = 'Card.Footer';

Card.Content.propTypes = {children: PropTypes.node, className: PropTypes.string};
Card.Annotations.propTypes = {children: PropTypes.node, className: PropTypes.string};
Card.Footer.propTypes = {children: PropTypes.node, className: PropTypes.string};
